.content {
  max-width: 1060px;
  margin: 50px auto 0;
}
.topContainer {
  background-color: #e7f2e9;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 576px) {
  .content {
    padding-top: 0px;
  }
}

